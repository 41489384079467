
import { Component, Vue } from 'vue-property-decorator'
import { ScenicInfo } from '@/types/scenic.d'

@Component({
  name: 'Scenic'
})
export default class extends Vue {
  private info = {
    projectName: ''
  }

  private sceneryId = ''
  private loading = false
  private tableData: ScenicInfo[] = []
  private showDialog = false
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<ScenicInfo> }>(this.$apis.scenic.selectScenicIntroduceByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'scenicAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'scenicUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.scenic.deleteScenicIntroduce, {
          projectId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'scenicDetail',
      params: { id }
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
